import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ["textInput","submitButton"]

  verifyTextInput(e) {
    if (!this.hasSubmitButtonTarget) {
      return
    }

    if (this.textInputTarget.value.length > 1) {
      this.submitButtonTarget.disabled = false
    }
  }

  updateText(e) {
    const isEnterWithoutShift = e.keyCode === 13 && !e.shiftKey

    if (isEnterWithoutShift) {
      e.preventDefault()
    }

    if (!this.hasSubmitButtonTarget) {
      return
    }

    if (this.textInputTarget.value.length > 1) {
      this.submitButtonTarget.disabled = false

      if (isEnterWithoutShift) {
        this.submitButtonTarget.click()
      }
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  clearTextInput(e) {
    this.textInputTarget.value = ""

    if (!this.hasSubmitButtonTarget) {
      return
    }

    this.submitButtonTarget.disabled = true
  }
}
