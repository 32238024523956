import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  static targets = ["toast"]

  connect() {
    const toast = new bootstrap.Toast(this.toastTarget)

    toast.show()

    setTimeout(() => {
      this.toastTarget.remove()
    }, 2050)
  }
}
