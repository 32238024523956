import { Controller } from "@hotwired/stimulus"
import { debounce } from "../helpers/timing_helper"

// Connects to data-controller="search"
export default class extends Controller {
  initialize() {
    this.search = debounce(this.search.bind(this), 300)
  }

  search(event) {
    this.element.requestSubmit();
  }
}
