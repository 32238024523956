import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameInput", "cancelButton"]

  connect() {
    const length = this.nameInputTarget.value.length;
    this.nameInputTarget.focus()
    this.nameInputTarget.setSelectionRange(length, length);
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.element.requestSubmit()
    }

    if (event.key === "Escape") {
      event.preventDefault()
      this.cancelButtonTarget.click()
    }
  }

  handleFocusOut() {
    this.cancelButtonTarget.click()
  }
}
