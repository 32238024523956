import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["titleInput", "cancelButton"]

  connect() {
    const length = this.titleInputTarget.value.length;
    this.titleInputTarget.focus()
    this.titleInputTarget.setSelectionRange(length, length);
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      this.titleInputTarget.blur()
      this.element.requestSubmit()
    }

    if (event.key === "Escape") {
      this.addHiddenField()
      this.titleInputTarget.blur()
      this.cancelButtonTarget.click()
    }
  }

  handleFocusOut() {
    this.cancelButtonTarget.click()
  }

  addHiddenField() {
    const form = this.element

    if (!form.querySelector('input[name="cancel"]')) {
      const hiddenField = document.createElement("input")
      hiddenField.type = "hidden"
      hiddenField.name = "cancel"
      hiddenField.value = "true"
      form.appendChild(hiddenField)
    }
  }
}
