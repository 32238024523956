import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"];

  connect() {
    this.updateTabs();
    this.observeMutations();
  }

  updateTabs() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const conversationId = pathSegments.pop();

    this.tabTargets.forEach(tab => {
      if (tab.dataset.tabValue === conversationId) {
        tab.classList.add("bg-gray");
      } else {
        tab.classList.remove("bg-gray");
      }
    });
  }

  observeMutations() {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.updateTabs();
        }
      }
    });

    observer.observe(this.element, { childList: true, subtree: true });
  }
}
