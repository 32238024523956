import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "submit_button", "loader", "remove_loader"]

  updateAvatar(event) {
    this.formTarget.submit()
    this.submit_buttonTarget.hidden = true
    this.loaderTarget.hidden = false
  }
}
