import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    default: String
  }
  static targets = [ "input", "aim" ]

  connect() {
    this.#setInputToAim()
  }

  setInputWithValidation(event) {
    const regex = /^[a-zA-Z]+$/

    var value = this.inputTarget.value
    var newValue = value.replace(/[^a-zA-Z ]/g, "")

    this.inputTarget.value = newValue
    this.#setInputToAim()
  }

  #setInputToAim() {
    if (this.inputTarget.value === "") {
      this.aimTarget.innerHTML = this.defaultValue
    } else {
      this.aimTarget.innerHTML = this.inputTarget.value
    }
  }
}
