import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkboxAll", "checkbox", "displayElement", "label"];
  static values = { singular: String, plural: String }

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.refresh);
    this.refresh();
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.refresh);
    this.refresh();
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.refresh);
    this.refresh();
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.refresh);
    this.refresh();
  }

  toggle(e) {
    e.preventDefault();
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked;
      this.triggerInputEvent(checkbox);
    });
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    if (checkboxesCheckedCount > 0) {
      this.displayElementTargets.forEach(displayElement => displayElement.classList.remove("d-none"));
      this.labelTarget.textContent = checkboxesCheckedCount === 1 ? 
        `1 ${this.singularValue}` : `${checkboxesCheckedCount} ${this.pluralValue}`;
    } else {
      this.displayElementTargets.forEach(displayElement => displayElement.classList.add("d-none"));
      this.labelTarget.textContent = "Selecionar todos";
    }
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }
}

