import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { post } from "@rails/request.js";

class Upload {
  static completedUploads = 0;
  static totalUploads = 0;

  constructor(file, onCompleteCallback) {
    this.directUpload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this);
    this.onCompleteCallback = onCompleteCallback;
  }

  process() {
    this.insertUpload();
  }

  async createArchive(error, blob) {
    if (error) {
      // Handle the error
    } else {
      const archiveData = { archive: { filename: blob.filename }, signed_blob_id: blob.signed_id };
    }
  }

  insertUpload() {
    const fileUpload = document.createElement("div");

    fileUpload.id = `upload_${this.directUpload.id}`;
    fileUpload.className = "row py-3 border-bottom";

    const wrapperCol = document.createElement("div");
    wrapperCol.className = "col";

    const fileName = document.createElement("span");
    fileName.textContent = this.directUpload.file.name;
    wrapperCol.prepend(fileName);
    fileUpload.append(wrapperCol);

    this.directUpload.create((error, blob) => {
      this.createArchive(error, blob);

      const inputFieldName = document.createElement("input");
      inputFieldName.name = `archives[${this.directUpload.id}][name]`;
      inputFieldName.value = blob.filename;
      inputFieldName.hidden = true;
      fileUpload.appendChild(inputFieldName);

      const inputFieldId = document.createElement("input");
      inputFieldId.name = `archives[${this.directUpload.id}][id]`;
      inputFieldId.value = blob.id;
      inputFieldId.hidden = true;
      fileUpload.appendChild(inputFieldId);

      Upload.completedUploads++;

      if (Upload.completedUploads === Upload.totalUploads) {
        this.onCompleteCallback();
      }
    });


    const progressWrapper = document.createElement("div");
    progressWrapper.className = "relative mt-2 h-4 overflow-hidden progress";
    progressWrapper.style = "height: 4px;";
    wrapperCol.appendChild(progressWrapper);

    this.progressBar = document.createElement("div");
    this.progressBar.className = "progress-bar bg-primary";
    progressWrapper.appendChild(this.progressBar);

    const uploadList = document.querySelector("#uploads");
    const blankState = document.querySelector("#blank-state");
    if (document.contains(blankState)) {
      blankState.remove();
    }
    uploadList.prepend(fileUpload);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.updateProgress(event));
  }

  updateProgress(event) {
    const percentage = (event.loaded / event.total) * 100;
    const progress = document.querySelector(`#upload_${this.directUpload.id} .progress-bar`);

    progress.style = `width: ${percentage}%;`;

    if (percentage === 100) {
      this.progressBar.className = "progress-bar bg-success"
    }
  }
}

export default class extends Controller {
  static targets = ["fileInput", "uploadsDiv", "submitButton", "loaderButton"];
  static values = {
    action: String
  }

  acceptFiles(event) {
    event.preventDefault();
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    Upload.completedUploads = 0;
    Upload.totalUploads = files.length;

    const onCompleteCallback = () => {
      this.submitButtonTarget.click();
      this.fileInputTarget.value = null;
    };

    [...files].forEach((f) => {
      new Upload(f, onCompleteCallback).process();
    });
  }
}
